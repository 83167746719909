exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-play-mdx": () => import("./../../../src/pages/play.mdx" /* webpackChunkName: "component---src-pages-play-mdx" */),
  "component---src-pages-projects-accenture-mdx": () => import("./../../../src/pages/projects/accenture.mdx" /* webpackChunkName: "component---src-pages-projects-accenture-mdx" */),
  "component---src-pages-projects-amazon-mdx": () => import("./../../../src/pages/projects/amazon.mdx" /* webpackChunkName: "component---src-pages-projects-amazon-mdx" */),
  "component---src-pages-projects-blinn-phong-mdx": () => import("./../../../src/pages/projects/blinn-phong.mdx" /* webpackChunkName: "component---src-pages-projects-blinn-phong-mdx" */),
  "component---src-pages-projects-bunnyworld-mdx": () => import("./../../../src/pages/projects/bunnyworld.mdx" /* webpackChunkName: "component---src-pages-projects-bunnyworld-mdx" */),
  "component---src-pages-projects-census-survey-explorer-mdx": () => import("./../../../src/pages/projects/census-survey-explorer.mdx" /* webpackChunkName: "component---src-pages-projects-census-survey-explorer-mdx" */),
  "component---src-pages-projects-federal-reserve-board-mdx": () => import("./../../../src/pages/projects/federal-reserve-board.mdx" /* webpackChunkName: "component---src-pages-projects-federal-reserve-board-mdx" */),
  "component---src-pages-projects-google-one-today-mdx": () => import("./../../../src/pages/projects/google-one-today.mdx" /* webpackChunkName: "component---src-pages-projects-google-one-today-mdx" */),
  "component---src-pages-projects-groove-n-bloom-mdx": () => import("./../../../src/pages/projects/groove-n-bloom.mdx" /* webpackChunkName: "component---src-pages-projects-groove-n-bloom-mdx" */),
  "component---src-pages-projects-last-moment-of-sun-mdx": () => import("./../../../src/pages/projects/last-moment-of-sun.mdx" /* webpackChunkName: "component---src-pages-projects-last-moment-of-sun-mdx" */),
  "component---src-pages-projects-monte-carlo-mdx": () => import("./../../../src/pages/projects/monte-carlo.mdx" /* webpackChunkName: "component---src-pages-projects-monte-carlo-mdx" */),
  "component---src-pages-projects-moodmemo-mdx": () => import("./../../../src/pages/projects/moodmemo.mdx" /* webpackChunkName: "component---src-pages-projects-moodmemo-mdx" */),
  "component---src-pages-projects-neurogenesis-mdx": () => import("./../../../src/pages/projects/neurogenesis.mdx" /* webpackChunkName: "component---src-pages-projects-neurogenesis-mdx" */),
  "component---src-pages-projects-nutrikitchen-mdx": () => import("./../../../src/pages/projects/nutrikitchen.mdx" /* webpackChunkName: "component---src-pages-projects-nutrikitchen-mdx" */),
  "component---src-pages-projects-open-data-campus-mdx": () => import("./../../../src/pages/projects/open-data-campus.mdx" /* webpackChunkName: "component---src-pages-projects-open-data-campus-mdx" */),
  "component---src-pages-projects-paint-ck-mdx": () => import("./../../../src/pages/projects/paint-ck.mdx" /* webpackChunkName: "component---src-pages-projects-paint-ck-mdx" */),
  "component---src-pages-projects-pixel-vessel-mdx": () => import("./../../../src/pages/projects/pixel-vessel.mdx" /* webpackChunkName: "component---src-pages-projects-pixel-vessel-mdx" */),
  "component---src-pages-projects-ray-traced-image-mdx": () => import("./../../../src/pages/projects/ray-traced-image.mdx" /* webpackChunkName: "component---src-pages-projects-ray-traced-image-mdx" */),
  "component---src-pages-projects-record-mdx": () => import("./../../../src/pages/projects/record.mdx" /* webpackChunkName: "component---src-pages-projects-record-mdx" */),
  "component---src-pages-projects-scooped-mdx": () => import("./../../../src/pages/projects/scooped.mdx" /* webpackChunkName: "component---src-pages-projects-scooped-mdx" */),
  "component---src-pages-projects-spaceboi-mdx": () => import("./../../../src/pages/projects/spaceboi.mdx" /* webpackChunkName: "component---src-pages-projects-spaceboi-mdx" */),
  "component---src-pages-projects-spacemail-mdx": () => import("./../../../src/pages/projects/spacemail.mdx" /* webpackChunkName: "component---src-pages-projects-spacemail-mdx" */),
  "component---src-pages-projects-stamply-mdx": () => import("./../../../src/pages/projects/stamply.mdx" /* webpackChunkName: "component---src-pages-projects-stamply-mdx" */),
  "component---src-pages-projects-sugar-showdown-mdx": () => import("./../../../src/pages/projects/sugar-showdown.mdx" /* webpackChunkName: "component---src-pages-projects-sugar-showdown-mdx" */),
  "component---src-pages-projects-swift-mentorship-program-mdx": () => import("./../../../src/pages/projects/swift-mentorship-program.mdx" /* webpackChunkName: "component---src-pages-projects-swift-mentorship-program-mdx" */),
  "component---src-pages-projects-time-travel-arcade-mdx": () => import("./../../../src/pages/projects/time-travel-arcade.mdx" /* webpackChunkName: "component---src-pages-projects-time-travel-arcade-mdx" */),
  "component---src-pages-work-computer-graphics-index-mdx": () => import("./../../../src/pages/work/computer-graphics/index.mdx" /* webpackChunkName: "component---src-pages-work-computer-graphics-index-mdx" */),
  "component---src-pages-work-creative-code-index-mdx": () => import("./../../../src/pages/work/creative-code/index.mdx" /* webpackChunkName: "component---src-pages-work-creative-code-index-mdx" */),
  "component---src-pages-work-game-design-index-mdx": () => import("./../../../src/pages/work/game-design/index.mdx" /* webpackChunkName: "component---src-pages-work-game-design-index-mdx" */),
  "component---src-pages-work-index-mdx": () => import("./../../../src/pages/work/index.mdx" /* webpackChunkName: "component---src-pages-work-index-mdx" */),
  "component---src-pages-work-software-dev-index-mdx": () => import("./../../../src/pages/work/software-dev/index.mdx" /* webpackChunkName: "component---src-pages-work-software-dev-index-mdx" */),
  "component---src-pages-work-ui-ux-index-mdx": () => import("./../../../src/pages/work/ui-ux/index.mdx" /* webpackChunkName: "component---src-pages-work-ui-ux-index-mdx" */)
}

